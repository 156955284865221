import { Component, OnInit } from '@angular/core';
import { UsersService } from 'app/core/services/users/users.service'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserComponent } from './user/user.component';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import Swal from 'sweetalert2';
import { AlertsService } from 'app/core/services/shared/alerts.service';
import { LocalstorageService } from 'app/core/localstorage/localstorage.service';
import * as XLSX from 'xlsx';
import { NewPasswordComponent } from './new-password/new-password.component';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {


	public page = 0;
	public rows = [];
	public totalRows = 0;
	public users: any[] = [];
	public dialogConfig = new MatDialogConfig();
	public rowsNumber = 0;
	public x = 0;
	public idUserStorage = 0;

	public city = [];
	public email = [];
	public userName = [];
	public phone = [];	
	public fileName: string;
	public totalRegisters: any;

	constructor(private useService: UsersService, private dialog: MatDialog, private route: Router, private param: ActivatedRoute, private modal: AlertsService, private localStorage: LocalstorageService) { }

	ngOnInit(): void {

		this.idUserStorage = this.localStorage.getUserInfo()['idAdministrator']
		this.getUsers()

	}

	getUsers(page = 0) {

		this.page = page;

		if (page == 0) {

			this.page = Number(this.param.snapshot.paramMap.get('page'))
		}

		this.useService.getUsers((this.page - 1) * 10).subscribe((res: any) => {

			this.totalRows = res.payload.count;
			this.x = Math.ceil(res.payload.count / Number(environment.RowsPage))
			this.rowsNumber = this.x
			this.rows = '-'.repeat(this.x).split('');
			this.users = res.payload.rows;

		}, (err: any) => {

			console.log(err.error.errors.detail);

		})

	}

	newUser() {

		const dialogRef = this.dialog.open(UserComponent, {
			width: '900px',
			data: {

				idUser: "",
				title: ""

			}
		})
		dialogRef.afterClosed().subscribe(result => { })
	}

	editUser(idUser: any) {

		const dialogRef = this.dialog.open(UserComponent, {
			width: '900px',
			data: {

				idUser: idUser,
				title: ""

			}
		})
		dialogRef.afterClosed().subscribe(result => { })

	}

	deleteUser(idUser: any) {

		Swal.fire({

			title: '¿Eliminar Administrador?',
			text: "No podra revertir está acción!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Si, Eliminarlo!',
			confirmButtonColor: "#0ba103",
			cancelButtonText: 'No, cancelar!',
			cancelButtonColor: "#d40000",
			reverseButtons: true

		}).then((result) => {

			if (result.value) {

				this.useService.deleteUser(idUser).subscribe((response: any) => {

					this.getUsers(0);

					this.modal.modalSuccess("Administrador eliminado")

				}, (err: any) => {



					this.modal.modalError('Algo paso intentelo más tarde');

				})

			}
		})

	}

	nextPage(page: any) {

		this.route.navigate(['/users/', page])
		this.getUsers(page);

	}

	downloadExcel() {

		this.fileName = `Usuarios${Math.random()}.xlsx`;
		this.useService.getUsersData().subscribe((res: any) => {

			this.manageExcelFile(res, this.fileName);

		});

	}

	manageExcelFile(res: any, fileName: string) {

		console.log(res.payload.rows);

		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.payload.rows);

		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Usuarios');
		
		XLSX.writeFile(wb, this.fileName);
	}

	newPasswordUser() {

		const dialogRef = this.dialog.open(NewPasswordComponent, {
			width: '900px',
			data: {

				

			}
		})
		dialogRef.afterClosed().subscribe(result => {  })


	}

}
