<div id="products" class="page-layout carded fullwidth inner-scroll cont-table">
    <div class="top-bg accent"></div>
    <div class="center">
        <div class="header accent mb-24 mb-md-0 px-4" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <i class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    account_box
                </i>
                <span class="logo-text h1">
                    Usuarios
                </span>
            </div>
            <div fxFlex="row" fxLayoutAlign="end center">
                <button style="font-size: 1.2em; margin-right: 30px;" mat-raised-button (click)="downloadExcel()" class="btn btn-light p-2 add-product-button fuse-white mt-24 mt-md-0">
                    <span>Descargar Usuarios</span>
                </button>
                <button style="font-size: 1.2em;" mat-raised-button (click)="newUser()" class="btn btn-light p-2 add-product-button fuse-white mt-24 mt-md-0">
                    <span>Nuevo Usuario</span>
                </button>
                <button style="font-size: 1.2em; margin-left: 30px;" mat-raised-button (click)="newPasswordUser()" class="btn btn-light p-2 add-product-button fuse-white mt-24 mt-md-0">
                    <span>Nueva contraseña</span>
                </button>
            </div>
        </div>
        <div class="row">
            <table class="table table-dark table-responsive-sm rounded">
                <thead>
                    <tr>
                        <th scope="col" class="text-center p-4">Nombre</th>
                        <th scope="col" class="text-center p-4">Correo</th>
                        <th scope="col" class="text-center p-4">Teléfono</th>
                        <th scope="col" class="text-center p-4">Rol</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody *ngIf="totalRows > 0">
                    <tr *ngFor="let item of users">
                        <td class="p-4" scope="row">{{item.name + " " +item.lastName }}</td>
                        <td class="p-4">{{item.email == null ? "N/A" : item.email}}</td>
                        <td class="p-4">{{item.phone == null ? "N/A" : item.phone}}</td>
                        <td class="p-4 text-center">{{item.position}}</td>
                        <td class="p-4"><i *ngIf="idUserStorage == item.idAdministrator" tooltip="Editar" (click)="editUser(item.idAdministrator)" class="material-icons green-600 text-center p-2 rounded-circle">create</i></td>
                        <td class="p-4"><i *ngIf="idUserStorage != item.idAdministrator" tooltip="Eliminar" (click)="deleteUser(item.idAdministrator)" class="material-icons red-600 p-2 text-center rounded-circle">remove_circle_outline</i></td>
                    </tr>
                </tbody>
                <tbody *ngIf="totalRows == 0">
                    <td class="p-3">No se encontraron registros</td>
                </tbody>
            </table>
            <nav class="col-12 mt-4" aria-label="..." *ngIf="x > 1">
                <ul class="pagination">
                    <li class="page-item" *ngIf="page-1 >= 1">
                        <a class="page-link" (click)="nextPage(page-1)" href="javascript:void(0);">Previous</a>
                    </li>
                    <li class="page-item disabled" *ngIf="page-1 === 0">
                        <span class="page-link" *ngIf="page-1 === 0">Previous</span>

                    </li>
                    <li class="page-item" *ngFor="let pages of rows; let i = index">
                        <a *ngIf="(i+1) == page" class="page-link activate" (click)="nextPage(i+1)" href="javascript:void(0);">{{i+1}}</a>
                        <a class="page-link" (click)="nextPage(i+1)" href="javascript:void(0);" *ngIf="(i+1) !== page">{{i+1}}</a>
                    </li>
                    <li class="page-item" *ngIf="rowsNumber > page">
                        <a class="page-link" (click)="nextPage(page+1)" href="javascript:void(0);">Next</a>
                    </li>
                    <li class="page-item disabled" *ngIf="rowsNumber === page">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>