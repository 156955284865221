import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService } from 'app/core/services/shared/alerts.service';
import { ProductService } from 'app/core/services/products/product.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NewPasswordService } from './new-password.service';

export interface DialogData {

	idProduct: string;

}

@Component({
  selector: 'app-newPassword',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
	imagenes: any[] = [];
  idProduct: any
  public formData: FormGroup;
  public errorMessage = "";

	constructor(private service: NewPasswordService, public modalpage: MatDialogRef<NewPasswordComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private modal: AlertsService) { }

	ngOnInit(): void {
    this.formData = new FormGroup({
			'email': new FormControl('', [Validators.required, Validators.email])
		})
  }

	actionData() {
    if (this.formData.valid) {
        let data = this.formData.value;
        let dataUser: any = {};
        for (const i in data) {
          const element = data[i];
          dataUser["email"] = element;
        }

        this.service.createNewPasswordFromUser(dataUser).subscribe((response: any) => {

          this.modalpage.close();
          this.modal.modalSuccessReload("La nueva contraseña se envió con éxito")


        }, (err: any) => {

          console.log(err.error.errors);

        })
    }
	}


}