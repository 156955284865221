import Swal from 'sweetalert2'
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AlertsService {

    constructor(private nav: Router) { }


    modalSuccess(message: any) {

        Swal.fire({

            title: message,
            icon: 'success',
            confirmButtonText: 'Aceptar',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            confirmButtonColor: "#0ba103",
            allowOutsideClick: false

        })

    }

    modalSuccessReload(message: any) {

        Swal.fire({

            title: message,
            icon: 'success',
            confirmButtonText: 'Aceptar',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            confirmButtonColor: "#0ba103",
            allowOutsideClick: false

        }).then((result) => {

            if (result.value) {

                window.location.reload();

            }

        })

    }

    modalSuccessRoute(message: any, route: any) {

        Swal.fire({

            title: message,
            icon: 'success',
            confirmButtonText: 'Aceptar',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            confirmButtonColor: "#0ba103",
            allowOutsideClick: false

        }).then((result) => {

            if (result.value) {

                this.nav.navigate([route]);

            }

        })

    }

    modalError(message: any) {

        Swal.fire({

            title: message,
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            confirmButtonColor: "#d40000",
            allowOutsideClick: false

        })

    }

}