import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root'
})
export class UsersService {

	constructor(private http: HttpClient) { }

	getService(url_service: any) {

		const url = `${environment.url_api}${url_service}`;

		return this.http.get(url);

	}

	postService(url_service: any, body: any) {

		const url = `${environment.url_api}${url_service}`;
		return this.http.post(url, body);

	}

	getUsers(page: any) {

		let url = '/administrators/?limit=10&offset=' + page;

		return this.getService(url)

	}


	createUser(body: any) {

		let url = '/administrators';

		return this.postService(url, body);

	}

	updateUser(body: any) {

		let complement = '/administrators/myself';

		const url = `${environment.url_api}${complement}`;
		return this.http.put(url, body);
		

	}

	getUser(idUser: any) {


		let url = '/administrators/' + idUser;

		return this.getService(url);

	}

	deleteUser(idUser: any){

		let complement = '/administrators/'+idUser;

		const url = `${environment.url_api}${complement}`;
		return this.http.delete(url);

	}

	changePassword(body: any) {
		
		let url = '/administrators/myself/restore-password';

		return this.postService(url, body);

	}

	getUsersData() {

		const url = '/customers';

		return this.getService(url);

	}

}
