import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewPasswordService {

  constructor(private http: HttpClient) { }

  patchService(url_service: any, body: any) {

		const url = `${environment.url_api}${url_service}`;
		return this.http.patch(url, body);

	}

  createNewPasswordFromUser(body: any) {

		let url = '/customers/forgot-password';

		return this.patchService(url, body);

	}
}
