import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ng2-tooltip-directive';

import { FuseSharedModule } from '@fuse/shared.module';

import { UsersComponent } from './users.component';
import { UserComponent } from './user/user.component'
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { NewPasswordComponent } from './new-password/new-password.component';


const routes = [
	{
		path: 'users/:page',
		component: UsersComponent
	}
];

@NgModule({
	declarations: [
		UsersComponent,
		UserComponent,
		NewPasswordComponent
	],
	imports: [
		RouterModule.forChild(routes),
		TranslateModule,
		FuseSharedModule,
		TooltipModule,
		MatTableModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule

	],
	exports: [
		UsersComponent
	],
	bootstrap: [
		UsersComponent
	],
	providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}]
	
})

export class UsersModule {
	
}
