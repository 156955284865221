<div class="container">
    <div class="row">
        <div class="col-12">
            <h1>Cambiar contraseña de usuario</h1>
        </div>
        <div class="col-12">
            <form [formGroup]="formData" (ngSubmit)="actionData()" class="needs-validations row m-auto align-items-center" id="formData">
                <div class="col-12 col-md-6 py-2">
                    <mat-form-field class="w-100-p">
                        <mat-label>Correo electrónico del usuario</mat-label>
                        <input matInput type="text" id="email" formControlName="email" placeholder="example@domain.com">
                        <mat-error *ngIf="formData.get('email').hasError('required')">
                            El campo Nombre es obligatorio
                        </mat-error>
                        <mat-error *ngIf="formData.get('email').hasError('email')">
                            Ingresa un correo electrónico válido
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-7 m-auto">
                    <button class="btn btn-outline-success btn-block p-3" style="font-size: 1em;">
						Enviar contraseña
					</button>
                </div>
                <div class="col-12 text-center mt-4">
                    <span style="color: white">{{errorMessage}}</span>
                </div>
            </form>
        </div>
    </div>
</div>