import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { UsersService } from 'app/core/services/users/users.service';
import { validarQueSeanIguales } from 'app/main/users/user/validators';
import { AlertsService } from 'app/core/services/shared/alerts.service';
import { LocalstorageService } from 'app/core/localstorage/localstorage.service';
import { AuthService } from 'app/core/services/auth/auth.service';



export interface DialogData {

	title: string;
	idUser: string;

}


@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss'],
	providers: [
		{ provide: MatFormFieldControl, useExisting: UserComponent }
	]
})
export class UserComponent implements OnInit {

	public formUser: FormGroup;
	public fileForm: File;
	public validateImage: boolean = false;
	public imageR: any;
	public validatePassB: boolean = true;
	public base64textString = "";
	public errorMessage = "";
	public photo64: any;
	public oldPhoto64 = "";

	constructor(public newUserCreate: MatDialogRef<UserComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private userServ: UsersService, private modal: AlertsService,public api: AuthService,
	public localStorage: LocalstorageService) {

		if (this.data.idUser == "") {

			this.formUser = new FormGroup({

				'id': new FormControl('', {}),
				'name': new FormControl('', Validators.required),
				'lastName': new FormControl('', Validators.required),
				'email': new FormControl('', [Validators.required, Validators.email, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9._%+-]+\.[a-z]{2,3}$")]),
				'password': new FormControl('', Validators.required),
				'confirmPassword': new FormControl('', Validators.required),
				'phone': new FormControl('', Validators.required),
				'oldPhoto64': new FormControl('', {})
			}, {
				validators: validarQueSeanIguales
			})

		} else {

			this.formUser = new FormGroup({

				'id': new FormControl('', {}),
				'name': new FormControl('', Validators.required),
				'lastName': new FormControl('', Validators.required),
				'email': new FormControl('', [Validators.required, Validators.email, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9._%+-]+\.[a-z]{2,3}$")]),
				'documentType': new FormControl('', Validators.required),
				'documentNumber': new FormControl('', Validators.required),
				'phone': new FormControl('', [Validators.required,Validators.maxLength(10)]),
				'oldPhoto64': new FormControl('', {})

			})
			this.getUser(this.data.idUser)

		}

	}

	getUser(idUser: any) {

		this.userServ.getUser(idUser).subscribe((res: any) => {

			this.formUser = new FormGroup({

				'id': new FormControl(idUser, {}),
				'name': new FormControl(res.payload.name, Validators.required),
				'lastName': new FormControl(res.payload.lastName, Validators.required),
				'email': new FormControl(res.payload.email, [Validators.required, Validators.email, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9._%+-]+\.[a-z]{2,3}$")]),
				'documentType': new FormControl(res.payload.documentType, Validators.required),
				'documentNumber': new FormControl(res.payload.documentNumber, Validators.required),
				'phone': new FormControl(res.payload.phone, [Validators.required, Validators.maxLength(10)]),
				'oldPhoto64': new FormControl(res.payload.photo, {})


			})
			this.oldPhoto64 = res.payload.photo;
			


		})


	}

	ngOnInit(): void {
		
	}

	actionUser() {
		
		/**
		 * si se cumple el condicional va a crear un administrador
		 */
		if (this.fileForm == undefined && this.formUser.get('id').value == "") {

			if (this.formUser.status == "VALID" && !this.validateImage) {

				let data = this.formUser.value;

				let dataUser: any = {};

				for (const i in data) {

					const element = data[i];
					if (i != "id" && i != "documentType" && i != "documentNumber" && i != "confirmPassword" && i != 'oldPhoto64') {

						if(i == "phone" || i == "documentNumber"){
							dataUser[i] = element.toString()
						}else {
							dataUser[i] = element
						}

					}


				}


				// dataUser['photo'] =  this.fileForm.name;
				dataUser['position'] = "ADMIN";


				if (this.formUser.get('id').value == "") {

					this.userServ.createUser(dataUser).subscribe((response: any) => {

						this.newUserCreate.close();
						this.modal.modalSuccessReload("Usuario creado")
						

					}, (err: any) => {

						if (err.error.errors.status_code == 400) {

							this.errorMessage = "El correo ya existe";
						}
						console.log(err.error.errors);

					})

				}

			}
		} else {
			
			if (this.fileForm == undefined) {

				this.validateImage = false;

			}
			
			if (this.formUser.status == "VALID" && !this.validateImage) {

				let data = this.formUser.value;

				let dataUser: any = {};

				for (const i in data) {

					const element = data[i];

					if (i != 'id' && i != 'email' && i != 'oldPhoto64') {
						if(i == "phone" || i == "documentNumber"){
							dataUser[i] = element.toString()
						}else {
							dataUser[i] = element
						}
						
					}

				}

				dataUser['photo'] = this.oldPhoto64;

				if (this.photo64 != "") {
					
					dataUser['photo'] =  this.photo64

				}

				dataUser['position'] = "ADMIN";
				
				if (this.formUser.get('id').value != "") {

					this.userServ.updateUser(dataUser).subscribe((response: any) => {

						this.newUserCreate.close();
						this.modal.modalSuccessReload("Usuario actualizado")
						this.api.getUserInformation().subscribe((res:any) => {
							
							let userInfo = {
				
								'name': res.payload.name,
								'lastName': res.payload.lastName,
								'email': res.payload.email,
								'idAdministrator': res.payload.idAdministrator,
								'photo': res.payload.photo
				
							}
							this.localStorage.setUserInfo(userInfo)
							
						})

					}, (err: any) => {

						console.log(err.error);

					})

				}

			}

		}


	}

	checarSiSonIguales(): boolean {
		return this.formUser.hasError('noSonIguales') &&
			this.formUser.get('password').dirty &&
			this.formUser.get('confirmPassword').dirty;
	}

	imageChange($event: any) {

		var reader = new FileReader();

		var file = $event.srcElement.files[0];

		if (file) {

			this.fileForm = file;

			this.validateImage = false;
			
			reader.onloadend = () => {
				
				this.photo64 = reader.result;
				this.imageR = reader.result;

			}
			reader.readAsDataURL(file);

		} else {

			this.validateImage = true;

			this.imageR = null;

		}




	}

	
}
