import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';

import { AuthService } from '../../../../core/services/auth/auth.service';
import { LocalstorageService } from '../../../../core/localstorage/localstorage.service';
import { Login } from '../../../../core/models/auth/auth.model';

@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	result = null;
	loginUser = { email: '', password: '' };
	private login: Login;

	/**
	 * Constructor
	 *
	 * @param {FuseConfigService} _fuseConfigService
	 * @param {FormBuilder} _formBuilder
	 */
	constructor(
		private _fuseConfigService: FuseConfigService,
		private _formBuilder: FormBuilder,
		private _router: Router,
		public api: AuthService,
		public localStorage: LocalstorageService
	) {
		// Configure the layout
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: true
				},
				toolbar: {
					hidden: true
				},
				footer: {
					hidden: true
				},
				sidepanel: {
					hidden: true
				}
			}
		};
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {

		this.loginForm = this._formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required]
		});

		if (this.localStorage.getToken()) {

			this._router.navigate(['/apps/e-commerce/orders/1']);

		}

	}

	/**
	 * Login user
	 */

	validateForm(): void {

		if (this.loginForm.invalid) {

			console.log('formulario inválido');

		} else {

			this.api.loginUser(this.loginUser).subscribe(data => {

				this.localStorage.setToken(data['payload'].token);

				this.api.getUserInformation().subscribe((res: any) => {

					let userInfo = {
		
						'name': res.payload.name,
						'lastName': res.payload.lastName,
						'email': res.payload.email,
						'idAdministrator': res.payload.idAdministrator,
						'photo': res.payload.photo
		
					}
		
					this.localStorage.setUserInfo(userInfo)
					
					if(res.payload.restoredPassword == false){

						this.localStorage.setOldPassword(this.loginUser.password)
					}
					
					this.localStorage.setPassword(res.payload.restoredPassword)
					this._router.navigate(['/apps/e-commerce/orders/1']);
		
				})
				
				// this.userInfo();
				

			}, (err: any) => {

				this.result = false;
				console.log(err.error.message);
				return false;

			});

		}

	}

	async userInfo() {

		

	}

}
