import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [

	{
        id        : 'users',
        title     : 'Usuarios',
        type      : 'item',
        url       : '/users/1',
        exactMatch: true
    },
    {
        id        : 'points-sale',
        title     : 'Puntos de venta',
        type      : 'item',
        url       : '/apps/e-commerce/points-sale/1',
        exactMatch: true
	},
	{
        id        : 'categories',
        title     : 'Categorias',
        type      : 'item',
        url       : '/apps/e-commerce/categories/1',
        exactMatch: true
    },
    {
        id        : 'products',
        title     : 'Productos',
        type      : 'item',
        url       : '/apps/e-commerce/products/1',
        exactMatch: true
    },
    {
        id        : 'orders',
        title     : 'Órdenes',
        type      : 'item',
        url       : '/apps/e-commerce/orders/1',
        exactMatch: true
	},
    {
        id        : 'trainings',
        title     : 'Capacitaciones',
        type      : 'item',
        url       : '/apps/e-commerce/trainings/1',
        exactMatch: true
	},
    {
        id        : 'notifications',
        title     : 'Notificaciones',
        type      : 'item',
        url       : '/apps/e-commerce/notifications/1',
        exactMatch: true
	},
    {
        id        : 'advertising',
        title     : 'Publicidad',
        type      : 'item',
        url       : '/apps/e-commerce/advertising',
        exactMatch: true
	}
];
