import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { url } from 'inspector';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	loginUrl = environment.url_api;

	constructor(private http: HttpClient) { }

	getService(url_service: any) {

		const url = `${environment.url_api}${url_service}`;

		return this.http.get(url);

	}

	postService(url_service: any, body:any) {

		const url = `${environment.url_api}${url_service}`;

		return this.http.post(url, body);

	}

	// login
	loginUser(user: any) {
		
		let url_service = "/administrators/auth";

		return this.postService(url_service, user);

	}

	/*
	* Get user information
	*/

	getUserInformation ()  {

		let url_service = "/administrators/myself";
		
		return this.getService(url_service);

	}

	forgotPassword(body: any) {

		let url_service = "/administrators/myself/forgot-password";

		return this.postService(url_service, body);

	}


}
