<div id="login" fxLayout="column">

    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/organic-nails.png">
            </div>

            <div class="title">INGRESA LA CUENTA</div>

            <div class="alert alert-danger mg-b-0" role="alert" *ngIf="result === false">
                Credenciales inválidas
            </div>

            <form (ngSubmit)="validateForm()" name="loginForm" [formGroup]="loginForm" novalidate>
                <mat-form-field appearance="outline">
                    <mat-label>Correo electrónico</mat-label>
                    <input matInput formControlName="email" 
                           [(ngModel)]="loginUser.email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="loginForm.get('email').hasError('required')">
                        Correo electrónico requerido
                    </mat-error>
                    <mat-error
                        *ngIf="!loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')">
                                Por favor, introduce una dirección de correo electrónico válida
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Contraseña</mat-label>
                    <input matInput type="password" formControlName="password"
                           [(ngModel)]="loginUser.password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        Contraseña requerida 
                    </mat-error>
                </mat-form-field>
                
                <button mat-raised-button color="accent" class="submit-button" aria-label="LOGIN"
                    [disabled]="loginForm.invalid" (click)="validateForm()">
                    INGRESAR
                </button>
                <div class="alert alert-info mg-b-0" role="alert" *ngIf="result === null">
                    Ingrese sus credenciales para continuar
                  </div>

                <!-- <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="space-between center">
                    <mat-checkbox class="remember-me" aria-label="Remember Me">
                      I accept terms and conditions
                    </mat-checkbox>
                </div> -->
            </form>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <a class="forgot-password" [routerLink]="'/pages/auth/forgot-password'">¿Se te olvidó tu contraseña?</a>
                <!-- <span class="text">Don't have an account?</span>
                <a class="link" [routerLink]="'/pages/auth/register'">Create an account</a> -->
            </div>

        </div>

    </div>

</div>
