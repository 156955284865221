import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { LocalstorageService } from '../localstorage/localstorage.service';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class OnnailsHttpInterceptor implements HttpInterceptor {

	constructor(private injector: Injector, private router: Router) {

	}

	intercept(req, next): any {
		const localStorage = this.injector.get(LocalstorageService);
		if (localStorage.loggedIn()) {
			
			if (localStorage.getPassword() == 'false' && localStorage.getPassword() != null) {

				const request = req.clone({
					setHeaders: {
						Authorization: `Bearer ${localStorage.getToken()}`,
						'Contenet-Type': 'application/json; cahrset=utf-8'
					}
				});
				this.router.navigate(['/pages/auth/new-password']);
				return next.handle(request);

			} else {

				const request = req.clone({
					setHeaders: {
						Authorization: `Bearer ${localStorage.getToken()}`,
						'Contenet-Type': 'application/json; cahrset=utf-8'
					}
				});
				return next.handle(request);
			}

		} else {
			this.router.navigate(['/pages/auth/login']);
			return next.handle(req);
		}

	}
}
