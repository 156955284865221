<div class="container">
    <div class="row">
        <div class="col-12" *ngIf="data.idUser == ''">
            <h1>Crear Usuario</h1>
        </div>
        <div class="col-12" *ngIf="data.idUser != ''">
            <h1>Editar Usuario</h1>
        </div>
        <div class="col-12">
            <form [formGroup]="formUser" (ngSubmit)="actionUser()" class="needs-validations row m-auto align-items-center" id="formUser">
                <div class="col-12 col-md-6 py-2">
                    <mat-form-field class="w-100-p">
                        <mat-label>Nombre</mat-label>
                        <input matInput type="text" id="name" formControlName="name">
                        <mat-error *ngIf="formUser.get('name').hasError('required')">
                            El campo Nombre es obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 py-2">
                    <mat-form-field class="w-100-p">
                        <mat-label>Apellido</mat-label>
                        <input matInput type="text" id="lastName" formControlName="lastName">
                        <mat-error *ngIf="formUser.get('lastName').hasError('required')">
                            El campo Apellido es obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 py-2">
                    <mat-form-field class="w-100-p">
                        <mat-label>Correo</mat-label>
                        <input matInput type="email" id="email" formControlName="email" *ngIf="data.idUser == ''">
                        <input matInput type="email" id="email" formControlName="email" readonly *ngIf="data.idUser != ''">
                        <mat-error *ngIf="formUser.get('email').hasError('required')">
                            El campo Correo es obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 py-2" *ngIf="data.idUser != ''">
                    <mat-form-field class="w-100-p">
                        <mat-label>Tipo de Documento</mat-label>
                        <mat-select id="documentType" formControlName="documentType">
                            <mat-option value="CC">
                                Cédula ciudadania
                            </mat-option>
                            <mat-option value="CE">
                                cédula de extranjería
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formUser.get('documentType').hasError('required')">
                            El campo Tipo de Documento es obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 py-2" *ngIf="data.idUser != ''">
                    <mat-form-field class="w-100-p">
                        <mat-label>Documento</mat-label>
                        <input matInput type="number" id="documentNumber" formControlName="documentNumber">
                        <mat-error *ngIf="formUser.get('documentNumber').hasError('required')">
                            El campo Documento es obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 py-2">
                    <mat-form-field class="w-100-p">
                        <mat-label>Telèfono</mat-label>
                        <input matInput type="number" id="phone" formControlName="phone">
                        <mat-error *ngIf="formUser.get('phone').hasError('required')">
                            El campo Teléfono es obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 py-2" *ngIf="data.idUser == ''">
                    <mat-form-field class="w-100-p">
                        <mat-label>Contraseña</mat-label>
                        <input matInput type="password" id="password" formControlName="password">
                        <mat-error *ngIf="formUser.get('password').hasError('required')">
                            El campo Contraseña es obligatorio
                        </mat-error>
                    </mat-form-field>
                    <mat-error class="mat-error" *ngIf="checarSiSonIguales()">Las contraseñas no coinciden</mat-error>
                </div>
                <div class="col-12 col-md-6 py-2" *ngIf="data.idUser == ''">
                    <mat-form-field class="w-100-p">
                        <mat-label>Confirmar Contraseña</mat-label>
                        <input matInput type="password" id="confirmPassword" formControlName="confirmPassword">
                        <mat-error *ngIf="formUser.get('confirmPassword').hasError('required')">
                            El campo Confirmar Contraseña es obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 py-2 mb-3" *ngIf="data.idUser != ''">

                    <div class="row align-items-top">
                        <div class="col-12 col-md-6 py-2">

                            <label for="image" class="btn btn-outline-success btn-block" style="font-size: 1em;"> Seleccionar Imagen</label>
                            <input type="file" id="image" (change)="imageChange($event)" accept="image/jpeg, image/png">
                            <mat-error *ngIf="validateImage">
                                El campo Foto es obligatorio
                            </mat-error>

                        </div>
                        <div class="col-12 col-md-6 py-2">

                            <div class="row">

                                <div class="col-12 text-center" *ngIf="imageR != undefined">

                                    <img class="m-auto" [src]="imageR" class="m-auto" width="150" height="80">

                                </div>
                                <div class="col-12 text-center" *ngIf="imageR == undefined">

                                    <img class="m-auto" [src]="oldPhoto64 | domseguro" class="m-auto" width="150" height="80">

                                </div>

                            </div>

                        </div>
                    </div>

                </div>
                <input type="hidden" formControlName="id">
                <input type="hidden" formControlName="oldPhoto64">
                <div class="col-12 col-md-7 m-auto">
                    <button class="btn btn-outline-success btn-block p-3" style="font-size: 1em;" *ngIf="data.idUser == ''">
						Guardar
					</button>
                    <button class="btn btn-outline-success btn-block p-3" style="font-size: 1em;" *ngIf="data.idUser != ''">
						Actualizar
					</button>
                </div>
                <div class="col-12 text-center mt-4">
                    <span style="color: white">{{errorMessage}}</span>
                </div>
            </form>
        </div>
    </div>
</div>