import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LocalstorageService {

	constructor() { }


	loggedIn() {
		if(localStorage.getItem('token')) {

			return true;

		}else {

			return false
		}
	}

	// token
	getToken(): string {
		return localStorage.getItem('token');
	}

	setToken(token: any): void {
		localStorage.setItem('token', token);
	}

	setPassword(state: any) {

		localStorage.setItem('restorePass', state);

	}
	

	getPassword() {

		return localStorage.getItem('restorePass');
		
	}

	setOldPassword(pass: any) {

		localStorage.setItem('oldPass', pass);

	}

	getOldPassword() {

		return localStorage.getItem('oldPass');
		
	}

	setUserInfo(info: any) {
		
		localStorage.setItem('user', JSON.stringify(info))

	}

	getUserInfo() {

		var retrievedObject = localStorage.getItem('user');
		return JSON.parse(retrievedObject)
	}

	clearStorage() {

		localStorage.clear();
	}

}
